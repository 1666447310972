"use client";
import ILog from "app/_lib/global/Log";
import dynamic from "next/dynamic";
import { useSearchParams } from "next/navigation";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { MeetingContext } from "../meeting-room/_contexts/MeetingContext";
import { IRootState } from "app/_contexts/ReduxProvider";

export type MeetingSession = {
   livekitToken: string | undefined;
   name: string | undefined;
   connectIntent: boolean;
};
const BreakoutRoom = dynamic(() => import("../meeting-room/_components/layout/BreakoutRoom"));

export default function MeetingRoomPage() {
   const livekitToken = useSelector((state: IRootState) => state.meeting.livekitToken);
   const params = useSearchParams();
   const recorderToken = params.get("recordertoken");
   const { room } = useContext(MeetingContext);
   if ((!!livekitToken || !!recorderToken) && room) {
      ILog.v("MeetingRoomPage", { livekitToken, room });
      // return "ROOM";
      return <BreakoutRoom />;
   }
   ILog.v("MeetingRoomPage", { livekitToken, room });
   // return "MeetingRoomPage";
}
